<div class="p-1">
  <div class="w-full flex justify-center flex-col">
    <div class="flex w-100 flex-1 justify-start mb-4">
      <div
        class="flex justify-center items-center w-[50px] h-[50px] rounded-full bg-green-100 border-green-50 border-8">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#039855" />
        </svg>
      </div>
    </div>
    <div>
      <h1 class="font-black text-[20px] text-black">{{isNew? 'Adicionar' : 'Alterar'}} Time</h1>
      <h6 class="text-zinc-400 font-light text-sm my-2">Preencha os dados e crie um novo time</h6>
    </div>
  </div>
  <form [formGroup]="formGroup">
    <div class="flex flex-col flex-1">
      <div class="flex flex-col">
        <div class="flex flex-col flex-1 mt-[15px]">
          <label class="text-black">Nome do time *</label>
          <input class="input-field" formControlName="name" maxlength="100" placeholder="ex: Manhã">
          <field-error-component [field]="'name'" [label]="'Nome do time'"
            [formGroup]="formGroup"></field-error-component>
        </div>

        <div class="flex flex-col flex-1 mt-[15px]">
          <div class="flex flex-col flex-1 mt-[15px]">
            <div>
              <label class="text-black">Dias de Trabalho</label>
            </div>
            <div class="flex">
              <div class="flex w-100 items-center">
                <mat-checkbox style="border-radius: 999px !important;" color="primary"
                  formControlName="monday">Seg.</mat-checkbox>
              </div>
              <div class="flex w-100 items-center">
                <mat-checkbox color="primary" formControlName="tuesday">Ter.</mat-checkbox>
              </div>
              <div class="flex w-100 items-center">
                <mat-checkbox color="primary" formControlName="wednesday">Qua.</mat-checkbox>
              </div>
              <div class="flex w-100 items-center">
                <mat-checkbox color="primary" formControlName="thursday">Qui.</mat-checkbox>
              </div>
              <div class="flex w-100 items-center">
                <mat-checkbox color="primary" formControlName="friday">Sex.</mat-checkbox>
              </div>
              <div class="flex w-100 items-center">
                <mat-checkbox color="primary" formControlName="saturday">Sab.</mat-checkbox>
              </div>
              <div class="flex w-100 items-center">
                <mat-checkbox color="primary" formControlName="sunday">Dom.</mat-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col flex-1 mt-[10px]">
          <label class="text-black">Horários de trabalho</label>
          <div class="flex w-full flex-row gap-2">
            <div class="flex flex-col w-50">
              <label class="text-black">De *:</label>
              <input class="input-field" formControlName="startHour" [mask]="'00:00'" [dropSpecialCharacters]="false"
                placeholder="ex: 08:00">
              <field-error-component [field]="'startHour'" [label]="'Horário de início de trabalho'"
                [formGroup]="formGroup"></field-error-component>
            </div>
            <div class="flex flex-col w-50">
              <label class="text-black">Até *:</label>
              <input class="input-field" formControlName="endHour" [mask]="'00:00'" [dropSpecialCharacters]="false"
                placeholder="ex: 17:00">
              <field-error-component [field]="'endHour'" [label]="'Horário de finalização de trabalho'"
                [formGroup]="formGroup"></field-error-component>
            </div>
          </div>
        </div>
        <div class="flex flex-col w-full">
          <div class="flex flex-col flex-1 mt-[15px]">
            <label class="text-black">Supervisores *</label>
            <mat-select class="input-field" formControlName="supervisors" multiple placeholder="Selecione"
              [compareWith]="compareWithObjectBase">
              <div class="input-select-filter w-full p-2 mb-2">
                <input class="input-select-filter input-field" type="text" (focus)="onFocusSupervisors()"
                  [formControl]="filterControlSupervisors" placeholder="Pesquise por um usuário...">
              </div>
              @for (item of listSupervisors; track item) {
              <mat-option [value]="item">{{item.name}}</mat-option>
              }
            </mat-select>
            <field-error-component [field]="'supervisors'" [label]="'Supervisor'"
              [formGroup]="formGroup"></field-error-component>
          </div>
          <div class="flex flex-col flex-1 mt-[15px]">
            <label class="text-black">Atendente *</label>
            <mat-select class="input-field" formControlName="agents" multiple placeholder="Selecione"
              [compareWith]="compareWithObjectBase">
              <div class="input-select-filter w-full p-2 mb-2">
                <input class="input-select-filter input-field" type="text" (focus)="onFocusAgents()"
                  [formControl]="filterControlAgents" placeholder="Pesquise por um usuário...">
              </div>
              @for (item of listAgents; track item) {
              <mat-option [value]="item">{{item.name}}</mat-option>
              }
            </mat-select>
            <field-error-component [field]="'agents'" [label]="'Atendentes'"
              [formGroup]="formGroup"></field-error-component>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="flex justify-between items-center w-full mt-2 gap-1">
  <button
    class="h-[45px] flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 w-[50%]"
    (click)="close()">Cancelar</button>
  <button
    class="h-[45px] flex justify-center items-center rounded-lg  text-white font-normal   bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-[50%]"
    (click)="save()">{{isNew? 'Adicionar' : 'Alterar'}}</button>
</div>