<div class="flex flex-1 flex-col px-5">
  <div class="flex flex-col justify-start">
    <div class="flex justify-start mb-2">
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
        <path d="M35 29H29V35H27V29H21V27H27V21H29V27H35V29Z" fill="#039855" />
      </svg>
    </div>
    <div>
      <h1 class="font-black text-[20px] text-black">{{isCreate? 'Adicionar' : 'Alterar'}} Departamento</h1>
      <h6 class="text-zinc-400 font-light text-sm my-2">Preencha os dados e crie um novo departamento</h6>
    </div>
  </div>
  <div class="flex w-full flex-col mb-[25px]">
    <div class="flex flex-col">
      <form [formGroup]="formGroup">
        <div class="flex flex-col flex-1 mt-[15px]">
          <label class="text-black">Nome do Departamento *</label>
          <input class="input-field" maxlength="100" formControlName="name" placeholder="Nome do departamento">
          <field-error-component [field]="'name'" [label]="'Nome do departamento'"
            [formGroup]="formGroup"></field-error-component>
        </div>

        <div class="flex flex-col flex-1 mt-[15px]">
          <label class="text-black">Descrição do departamento *</label>
          <input class="input-field" maxlength="100" formControlName="description"
            placeholder="Descrição do departamento">
          <field-error-component [field]="'description'" [label]="'Descrição do departamento'"
            [formGroup]="formGroup"></field-error-component>
        </div>
      </form>

      <div class="flex flex-col flex-1 mt-[15px]">
        <h1 class="font-black text-[20px] text-black">Times</h1>
        <h6 class="text-zinc-400 font-light text-sm my-2">Defina o horário de funcionamento e os atendentes de cada time
          do
          seu departamento</h6>
      </div>

      <div class="flex flex-col flex-1 mt-[15px]">
        @for (item of teamList; track $index) {
        <div class="border-3 border-zinc-100 rounded-lg p-2 flex flex-col">
          <div class="flex w-100 justify-start items-center border-b-2 border-zinc-100 p-2">
            <div
              class="flex justify-center items-center w-[50px] h-[50px] rounded-full bg-indigo-200 border-indigo-100 border-8">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 13.3334V6.66675M8 13.3334V2.66675M4 13.3334V9.33341" stroke="#4213F6" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
            <div class="flex justify-center items-center h-full">
              <label class="text-black ml-4">{{item.name}} ({{item.startHour}}-{{item.endHour}}) -
                {{getWeekDays(item)}}</label>
            </div>
            <div class="flex flex-1 justify-end items-center h-full ">
              <i class="ph ph-check-circle text-green-500 text-[24px]" *ngIf="item.active"></i>
              <i class="ph ph-x-circle text-red-500 text-[24px]" *ngIf="!item.active"></i>
            </div>
          </div>

          <div class="flex flex-col flex-1 mt-2">
            <label class="mb-2">{{item.supervisors?.length}} supervisor(es) | {{item.agents?.length}} operador(es)
            </label>
            <div class="flex flex-row gap-2">
              <button (click)="expand($index)"
                class="w-50 p-2.5 mt-1 mb-2 border-2 border-[#BDBDBD] rounded-lg  text-[inherit] font-[inherit] hover:bg-gray-50 focus:border-[#BDBDBD] focus:outline-none">
                <i class="ph ph-caret-down" *ngIf="indexExpanded!==$index"></i>
                <i class="ph ph-caret-up" *ngIf="indexExpanded===$index"></i> Exibir time</button>
              <button
                class="w-50 p-2.5 mt-1 mb-2 border-2 border-[#BDBDBD] rounded-lg  text-[inherit] font-[inherit] hover:bg-gray-50 focus:border-[#BDBDBD] focus:outline-none"
                (click)="editTeam($index)"> <i class="ph-light ph-pencil-simple"></i> Editar time</button>
              <button
                class="w-50 p-2.5 mt-1 mb-2 border-2 border-[#BDBDBD] rounded-lg  text-[inherit] font-[inherit] hover:bg-gray-50 focus:border-[#BDBDBD] focus:outline-none"
                (click)="activateInactivateTeam(item, $index)">
                <i class="ph-light ph-lock" *ngIf="item.active"></i>
                <i class="ph-light ph-lock-open" *ngIf="!item.active"></i>{{item.active? 'Inativar' : 'Ativar'}}
                time</button>
              <button
                class="w-50 p-2.5 mt-1 mb-2 border-2 border-[#BDBDBD] rounded-lg  text-[inherit] font-[inherit] hover:bg-gray-50 focus:border-[#BDBDBD] focus:outline-none"
                (click)="deleteTeam($index)"> <i class="ph-light ph-trash-simple"></i> Remover time</button>
            </div>
          </div>
          <div class="collapsible">
            <div class="flex w-full flex-row gap-2" [hidden]="indexExpanded!=$index?true:null">
              <div class="flex flex-col w-50 gap-4">
                <label class="text-black mb-2">Supervisores</label>
                @for (agent of item.supervisors; track $index) {
                <div class="flex w-100">
                  <img class="w-8 h-8 rounded-full object-cover" [src]="getUserImage(agent)" alt="">
                  <div class="flex flex-col ml-2">
                    <h3>{{agent.name}}</h3>
                    <span class="text-xs text-gray-300">{{agent.email}}</span>
                  </div>
                </div>
                }
              </div>
              <div class="flex flex-col w-50 gap-4">
                <label class="text-black mb-2">Operadores</label>
                @for (agent of item.agents; track $index) {
                <div class="flex w-100">
                  <img class="w-8 h-8 rounded-full object-cover" [src]="getUserImage(agent)" alt="">
                  <div class="flex flex-col ml-2">
                    <h3>{{agent.name}}</h3>
                    <span class="text-xs text-gray-300">{{agent.email}}</span>
                  </div>
                </div>
                }
              </div>
            </div>
          </div>
        </div>
        }
      </div>
      <div class="flex flex-1 justify-end mt-2">
        <button
          class="py-2 px-14 mt-1 mb-2 rounded-lg text-white bg-[#039855] font-normal hover:bg-[#24cc80] focus:outline-none"
          (click)="addNewTeam()">Adicionar novo time</button>
      </div>
    </div>
  </div>
  <div class="flex justify-end items-center w-full gap-4 mt-3 mb-4">
    <button
      class="h-[45px] px-14 flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100"
      (click)="back()">Cancelar</button>
    <button
      class="h-[45px] px-14 flex justify-center items-center rounded-lg  text-white font-normal   bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none"
      (click)="save()">Salvar</button>
  </div>
</div>