<div class="flex flex-1 flex-col px-5">

  <div class="mb-4">
    <h1 class="font-black text-[20px] text-black">Departamentos</h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">Organize seus departamentos conforme seu processo de atendimento
    </h6>
    <hr />
  </div>

  <div class="flex flex-col w-full justify-center items-center pt-10" *ngIf="pager.total == 0">
    <div class="relative">
      <svg width="156" height="156" viewBox="0 0 156 156" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_3966_18110)">
          <circle cx="78.125" cy="78.125" r="28.375" stroke="url(#paint0_linear_3966_18110)" stroke-width="1.5"
            shape-rendering="crispEdges" />
        </g>
        <circle cx="78.125" cy="78.125" r="51.375" stroke="url(#paint1_linear_3966_18110)" stroke-width="1.5" />
        <circle cx="78" cy="78" r="77.25" stroke="url(#paint2_linear_3966_18110)" stroke-width="1.5" />
        <path
          d="M70 79C71.1 79 72 78.1 72 77C72 75.9 71.1 75 70 75C68.9 75 68 75.9 68 77C68 78.1 68.9 79 70 79ZM71.13 80.1C70.76 80.04 70.39 80 70 80C69.01 80 68.07 80.21 67.22 80.58C66.48 80.9 66 81.62 66 82.43V84H70.5V82.39C70.5 81.56 70.73 80.78 71.13 80.1ZM86 79C87.1 79 88 78.1 88 77C88 75.9 87.1 75 86 75C84.9 75 84 75.9 84 77C84 78.1 84.9 79 86 79ZM90 82.43C90 81.62 89.52 80.9 88.78 80.58C87.93 80.21 86.99 80 86 80C85.61 80 85.24 80.04 84.87 80.1C85.27 80.78 85.5 81.56 85.5 82.39V84H90V82.43ZM82.24 79.65C81.07 79.13 79.63 78.75 78 78.75C76.37 78.75 74.93 79.14 73.76 79.65C72.68 80.13 72 81.21 72 82.39V84H84V82.39C84 81.21 83.32 80.13 82.24 79.65ZM74.07 82C74.16 81.77 74.2 81.61 74.98 81.31C75.95 80.93 76.97 80.75 78 80.75C79.03 80.75 80.05 80.93 81.02 81.31C81.79 81.61 81.83 81.77 81.93 82H74.07ZM78 74C78.55 74 79 74.45 79 75C79 75.55 78.55 76 78 76C77.45 76 77 75.55 77 75C77 74.45 77.45 74 78 74ZM78 72C76.34 72 75 73.34 75 75C75 76.66 76.34 78 78 78C79.66 78 81 76.66 81 75C81 73.34 79.66 72 78 72Z"
          fill="#4213F6" />
        <defs>
          <filter id="filter0_d_3966_18110" x="45.1" y="48.1" width="66.05" height="66.05" filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha" />
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="1.95" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.999316 0 0 0 0 0.999316 0 0 0 0 0.999316 0 0 0 0.06 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3966_18110" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3966_18110" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_3966_18110" x1="78.125" y1="49" x2="78.125" y2="96.7949"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#D0D0FF" />
            <stop offset="1" stop-color="#597EFF" stop-opacity="0.02" />
          </linearGradient>
          <linearGradient id="paint1_linear_3966_18110" x1="78.125" y1="26" x2="78.125" y2="111.538"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#D0D0FF" />
            <stop offset="1" stop-color="#597EFF" stop-opacity="0.02" />
          </linearGradient>
          <linearGradient id="paint2_linear_3966_18110" x1="78" y1="0" x2="78" y2="128" gradientUnits="userSpaceOnUse">
            <stop stop-color="#D0D0FF" />
            <stop offset="1" stop-color="#597EFF" stop-opacity="0.02" />
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div class="flex justify-center flex-col items-center mt-10">
      <h2 class="text-black font-black text text-2xl">Você ainda não tem departamentos adicionados</h2>
      <h5 class="text-gray-600 font-light my-3">Adicione seu primeiro</h5>
      <button
        class="mt-3 w-[250px] h-[46px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal  hover:bg-[#1340f6]"
        (click)="addDepartment()" *ngIf="isAdmin()">+ Adicionar departamento</button>
    </div>

  </div>

  <div class="w-full flex items-center" *ngIf="pager.total > 0">
    <div class="flex w-full justify-end gap-4">
      <div class="flex justify-end mb-4">
        <button
          class="w-[250px] h-[46px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal  hover:bg-[#1340f6]"
          (click)="addDepartment()" *ngIf="isAdmin()">+ Adicionar departamento</button>
      </div>
    </div>
  </div>

  <div class="w-full max-h-[calc(100vh-240px)] flex gap-3" *ngIf="pager.total > 0">
    <div class="w-[100%]">
      <div class="flex flex-wrap gap-4 w-[100%]">
        <div
          class="cursor-pointer bg-white p-4 shadow rounded-lg w-[10%] custom-max:w-[18%] custom-min:w-[30%] md:w-[40%] sm:w-[100%] mobile:w-[100%] h-[200px] border-2 border-gray-100"
          *ngFor="let item of pager.list" (click)="edit(item)">
          <div class="flex justify-between items-center">
            <i class="ph ph-users-three text-[24px]"></i>
            <div class="inline-block text-left" x-data="{ menu: false }">
              <button x-on:click="menu = !menu; $event.stopPropagation();" type="button"
                class="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none relative" id="menu-button"
                aria-expanded="true" aria-haspopup="true">
                <span class="sr-only"></span>
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                  aria-hidden="true">
                  <path
                    d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                </svg>
              </button>
              <div x-show="menu" x-on:click.away="menu = false"
                class="absolute w-40 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                <div class="" role="none">
                  <a href="javascript:void(0)"
                    class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                    role="menuitem" tabindex="-1" id="menu-item-0" [routerLink]="['/user']">
                    Usuários
                  </a>
                </div>
                <div class="" role="none">
                  <a href="javascript:void(0)"
                    class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                    role="menuitem" tabindex="-1" id="menu-item-0" (click)="edit(item); $event.stopPropagation();">
                    Editar
                  </a>

                </div>
                <div class="" role="none">
                  <a href="javascript:void(0)"
                    class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                    role="menuitem" tabindex="-1" id="menu-item-0" (click)="delete(item); $event.stopPropagation();" *ngIf="isAdmin()">
                    Remover
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col h-full justify-center items-start whitespace-pre-line">
            <h2 class="text-black">{{item.name}}</h2>
            <span class="text-sm mt-2 text-gray-400">{{ getCountAttendants(item._id) }}</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>