import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CardComponentModule, SharedModule } from 'lib-trend-core';
import { DepartmentEditComponent } from './department-edit/department-edit.component';
import { DepartmentComponent } from './department.component';
import { RouterLink } from '@angular/router';
import { DepartmentRoutingModule } from './department-routing.module';
import { DepartmentTeamEditComponent } from './department-team-edit/department-team-edit.component';

@NgModule({
  imports: [
    SharedModule,
    RouterLink,
    CardComponentModule,
    DepartmentRoutingModule,
  ],
  declarations: [
    DepartmentComponent,
    DepartmentEditComponent,
    DepartmentTeamEditComponent
  ],
  exports: [
    DepartmentComponent,
    DepartmentEditComponent,
    DepartmentTeamEditComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DepartmentModule { }
