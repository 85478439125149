import { Component, EventEmitter, Inject, Injector, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent, Team, TeamService, User, UserService } from 'lib-trend-core';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'department-team-edit-component',
  templateUrl: './department-team-edit.component.html',
  styleUrl: './department-team-edit.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class DepartmentTeamEditComponent extends AbstractComponent {
  filterControlAgents: FormControl<string> = new FormControl<string>('');
  filterControlSupervisors: FormControl<string> = new FormControl<string>('');

  formUser: FormGroup;

  @Output() onDelete: EventEmitter<Team> = new EventEmitter<Team>();
  @Output() onEdit: EventEmitter<Team> = new EventEmitter<Team>();

  userList: User[];
  listAgents: Array<User> = new Array<User>();
  listSupervisors: Array<User> = new Array<User>();

  team: Team;

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<DepartmentTeamEditComponent>,
    public teamService: TeamService,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: Team,
  ) {
    super(injector);
    this.team = {} as Team;
    this.createForm();
    this.loadUser();
    this.fetchFilterUsers();
  }

  ngOnInit() {
    this.isNew = (!!this.data ? false : true);
    if (!this.isNew) {
      this.team = this.data;
      this.formGroup.patchValue(this.team);
      this.formGroup.get('supervisors').setValue(this.team.supervisors);
      this.formGroup.get('agents').setValue(this.team.agents);
    }
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      _id: [null],
      name: [null, Validators.required],
      startHour: [null, [Validators.required, Validators.pattern(this.timeRegex)]],
      endHour: [null, [Validators.required, Validators.pattern(this.timeRegex)]],
      supervisors: [null, Validators.required],
      agents: [null, Validators.required],
      monday: [false],
      tuesday: [false],
      wednesday: [false],
      thursday: [false],
      friday: [false],
      saturday: [false],
      sunday: [false],
    });
    this.formGroup.valueChanges.subscribe(value => {
      Object.assign(this.team, value);
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  save() {
    if (!this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup);
      return;
    }

    if (!this.team.monday && !this.team.tuesday && !this.team.wednesday && !this.team.thursday && !this.team.friday && !this.team.saturday && !this.team.sunday) {
      this.alertService.warning('Selecione pelo menos um dia da semana.');
      return;
    }
    if (this.isNew) {
      this.team.active = true;
      this.dialogRef.close(this.team);
      this.alertService.success('Time adicionado com sucesso.');
    } else {
      this.dialogRef.close(this.team);
      this.alertService.success('Time alterado com sucesso.');
    }
  }

  private loadUser() {
    this.userService.getList().subscribe({
      next: (value) => {
        this.userList = value;
        this.listAgents = value;
        this.listSupervisors = value;
      },
      error: (err) => {
        this.alertService.error(err.error.message);
      },
      complete: () => {
      },
    });
  }

  private fetchFilterUsers(): void {
    this.filterControlAgents.valueChanges.pipe(
      debounceTime(300),
    ).subscribe((value: string) => {
      const currentValue = this.formGroup.get('agents')?.value || [];

      this.listAgents = this.userList.filter(user =>
        user.name.toLowerCase().includes(value.toLowerCase()) &&
        !currentValue.includes(user._id)
      );

      currentValue.forEach(userId => {
        const existingUser = this.userList.find(user => user._id === userId);
        if (existingUser) {
          this.listAgents.push(existingUser);
        }
      });
    });

    this.filterControlSupervisors.valueChanges.pipe(
      debounceTime(300),
    ).subscribe((value: string) => {
      const currentValue = this.formGroup.get('supervisors')?.value || [];

      this.listSupervisors = this.userList.filter(user =>
        user.name.toLowerCase().includes(value.toLowerCase()) &&
        !currentValue.includes(user._id)
      );

      currentValue.forEach(userId => {
        const existingUser = this.userList.find(user => user._id === userId);
        if (existingUser) {
          this.listSupervisors.push(existingUser);
        }
      });
    });
  }

  onFocusAgents(): void {
    this.listAgents = this.userList;
  }

  onFocusSupervisors(): void {
    this.listSupervisors = this.userList;
  }
}
